const spawnChance = 0.8;
const startX = 0.74;
const rangeX = 0.05;
const circleAngle = 2*Math.PI;
let baseXVelocity = 1;
let baseYVelocity = 3;
const velocityMultiplier = 2;
const baseAcceleration = 1;
const accelerationReductionMultiplier = 0.975;
let containerWidth, containerHeight;
let baseXSpeedMultiplier = 0.3; // Overwritten by resizeCanvas

class Sprite {
  constructor(i) {
    const rangeXModifier = Math.random() * rangeX;
    const velocityRandomModifier = Math.random();
    
    this.hasSwitched = false;
    this.xVelocity = baseXVelocity + velocityRandomModifier * (velocityMultiplier - baseXSpeedMultiplier);
    this.yVelocity = baseYVelocity + velocityRandomModifier * velocityMultiplier;
    this.acceleration = baseAcceleration;
    this.yAcceleration = baseAcceleration * 0.7;
    this.radius = 1 + Math.random() * 3;
    this.x = containerWidth * startX + containerWidth * rangeXModifier;
    this.y = containerHeight + this.radius;
    // @TODO remove magic number 4 with responsive value so that particles are always heading to just under
    // mid-y of the page
    //this.xSpeedMultiplier = baseXSpeedMultiplier + 4 * (rangeX * (1 - (rangeXModifier / rangeX)));
    this.xSpeedMultiplier = baseXSpeedMultiplier;
    this.ySpeedMultiplier = 1 + Math.random() * 2;
    
    // Set color based on velocity & xSpeedMulltiplier
    const maxVelocity = baseXVelocity + velocityMultiplier - baseXSpeedMultiplier;
    this.hue = Math.round(360 * ((this.xVelocity - baseXVelocity) / (maxVelocity - baseXVelocity)));
    this.saturation = Math.random() * 30;
    this.lightness = 20;
    this.opacity = 1;
    this.opacityReducer = 0.005 + Math.random() * 0.05;
  }

  update() {
    if (this.y < -this.radius) return true; // Remove particles if off the screen
    if (this.acceleration < 0.01) return true; // Remove particles if slowed to a stop
    if (this.opacity <= 0) return true;

    if (!this.hasSwitched) {
      this.x = this.x + this.xVelocity * this.acceleration * this.xSpeedMultiplier;
      this.y = this.y - this.yVelocity * this.acceleration;
    } else {
      const swirlModifier = Math.sin(this.x * 0.08 + this.y * 0.01);
      this.x = this.x + this.xVelocity * this.acceleration * -this.xSpeedMultiplier - swirlModifier - 1 + Math.random();
      this.y = this.y - this.yVelocity * this.yAcceleration * this.ySpeedMultiplier;
      this.yAcceleration = this.yAcceleration * accelerationReductionMultiplier;

      this.opacity -= this.opacityReducer;
      this.radius += -0.07 + Math.random() * 0.2;

      this.acceleration = this.acceleration * accelerationReductionMultiplier;
    }

    // This is only performed once, when the particle switches direction at the window edge
    if (!this.hasSwitched && this.x > containerWidth) {
      this.hasSwitched = true;

      // Increase xModifier for period after bounce
      this.xSpeedMultiplier *= 1.1 + 0.5 * Math.random();

      this.radius *= 1.8;
      
      // Set saturation & lightness for after bounce
      this.saturation = 60 + Math.random() * 30;
      this.lightness = 30 + 50 * Math.random();
    }

    return false;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, circleAngle);
    ctx.fillStyle = `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.opacity})`;
    ctx.fill();
  }
};

export default class {
  constructor(opts) {
    if (!opts.target) throw 'error: {target: "id"} must be supplied on instantiating class';

    this.container = document.getElementById(opts.target);
    this.ctx = this.container.getContext('2d');
    this.sprites = [];

    // Check & resize canvas before doing anything else
    this.resizeCanvas();
    window.onresize = this.resizeCanvas.bind(this);

    this.raf = requestAnimationFrame(this.draw.bind(this));  
  }

  draw() {
    const ctx = this.ctx;

    ctx.clearRect(0, 0, containerWidth, containerHeight);
    
    let chance = Math.random();

    if (chance < spawnChance) {
      this.sprites.push(new Sprite());
    }

    for (let i=this.sprites.length-1; i >= 0; i--) {
      const destroy = this.sprites[i].update();

      if (destroy) this.sprites.splice(i,1);
    }

    for (let i=this.sprites.length-1; i >= 0; i--) {
      this.sprites[i].draw(ctx);
    }

    requestAnimationFrame(this.draw.bind(this));
  }

  resizeCanvas() {
    const ctx = this.ctx;

    containerWidth = this.container.clientWidth;
    containerHeight = this.container.clientHeight;

    ctx.canvas.width = containerWidth;
    ctx.canvas.height = containerHeight;

    baseXVelocity = (3 - 3 / (window.innerWidth / 200))
    baseXSpeedMultiplier =  1 * (1.4 - 1.4 / (window.innerWidth / 100));
  }
};
