import HeroCanvas from './modules/hero-canvas';
import Events from './modules/events';

export default class {
  constructor() {
    this.heroCanvas = new HeroCanvas({ target: 'hero-canvas' });
    this.events = new Events();

    this.events.bind();
  }

  addListeners() {
    document.body.addEventListener('click', )
    //border-image: conic-gradient(from 0deg, red, blue, red);
  }
}
